import React, { useState } from "react"
import styled from "styled-components"
import { useMutation } from "react-apollo-hooks"
import gql from "graphql-tag"

// import material ui
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"

// import app component
import Edges from "../components/edges"
// import Button from "components/button"

// import { useStore } from "../store"

import { useStore } from "../store"

const ResetPassword = props => {
  const dispatch = useStore()[1]
  const { location } = props

  const changePassword = useMutation(CHANGE_PASSWORD)

  const key = findGetParameter("key")
  const login = findGetParameter("login")

  const [needsReset, setNeedsReset] = useState(true)

  const [formError, updateFormErrors] = useState(false)
  //   const [isLoading, updateIsLoading] = useState(false)
  const [password, setPassword] = useState("")
  const [passwordConfirmation, setPasswordConfirmation] = useState("")

  function findGetParameter(parameterName) {
    let result = null,
      tmp = []
    let items = location.search.substr(1).split("&")
    for (let i = 0; i < items.length; i++) {
      tmp = items[i].split("=")
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1])
    }
    return result
  }

  const onSubmit = async () => {
    if (!login || !key) {
      updateFormErrors("Reset link invalid")
      return
    }
    if (!password) {
      updateFormErrors("Please enter a new password")
      return
    }

    dispatch({ type: "SET_PROGRESS", payload: true })

    // logout first to delete local storage data
    dispatch({ type: "LOGOUT" })
    let response
    let message
    try {
      response = await changePassword({
        variables: {
          username: login,
          key: key,
          password: password
        }
      })
    } catch (e) {
      message = e.message
    }

    if (message && message.includes("link is invalid")) {
      updateFormErrors("Password reset link is invalid.")
    } else {
      message && updateFormErrors(message)
    }

    if (!message && response?.data?.resetUserPassword?.clientMutationId) {
      setNeedsReset(false)
    } else if (!message) {
      updateFormErrors("Password reset failed.")
    }

    dispatch({ type: "SET_PROGRESS", payload: false })
  }

  const catchReturn = event => {
    if (event.charCode === 13) {
      // enter key pressed
      event.preventDefault()
      onSubmit()
    }
  }
  return (
    <Container>
      <StyledEdges size="sm">
        <Typography variant="h3" gutterBottom>
          {!!needsReset ? "Reset your password" : "Successful password reset!"}
        </Typography>
        <FormError>{formError}</FormError>
        {!!needsReset ? (
          <div>
            <StyledTextField
              margin="normal"
              required
              fullWidth
              type="password"
              label="New password"
              placeholder="Enter a new password"
              onChange={({ target }) => setPassword(target.value)}
              onKeyPress={event => catchReturn(event)}
            />
            <StyledTextField
              margin="normal"
              required
              fullWidth
              type="password"
              label="Confirm New password"
              placeholder="Confirm your new password"
              onChange={({ target }) => setPasswordConfirmation(target.value)}
              onKeyPress={event => catchReturn(event)}
            />
            <Button
              fullWidth
              disabled={password === "" || password !== passwordConfirmation}
              onClick={() => onSubmit()}
            >
              Confirm new password
            </Button>
          </div>
        ) : (
          <div>
            <Typography gutterBottom>
              You can now use your new password to login.
            </Typography>
            <Button
              fullWidth
              onClick={() =>
                dispatch({ type: "SET_LOGIN_DIALOG", payload: true })
              }
            >
              Login
            </Button>
          </div>
        )}
      </StyledEdges>
    </Container>
  )
}

export default ResetPassword

const StyledEdges = styled(Edges)`
  padding: 100px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`
const Container = styled.div`
  background: white;
`
const StyledTextField = styled(TextField)`
  && {
    margin: 10px 0;
  }
`
const FormError = styled(Typography)`
  && {
    color: black;
  }
`

const CHANGE_PASSWORD = gql`
  mutation ChangePassword(
    $key: String!
    $username: String!
    $password: String!
  ) {
    resetUserPassword(
      input: {
        key: $key
        login: $username
        password: $password
        clientMutationId: "uniqueId"
      }
    ) {
      clientMutationId
    }
  }
`
